<template>
  <div @focusout="closeDropdown">
    <singleselect-text
      v-model="status"
      :options="timeSlotList"
      :allow-empty="true"
      :taggable="false"
      v-show="changeslottypepvmid === pvm.id"
      @select="onSelectTimeSlot(pvm.id, $event)"
      :ref="'dynamicTimeSlotRefs-' + pvm.id"
    >
      <template #clear v-if="pvm.slottype">
        <i
          @mousedown.prevent.stop="onSelectTimeSlot(pvm.id, '')"
          class="multiselect__clear fa fa-times"
          aria-label="Clear"
        ></i>
      </template>
    </singleselect-text>
    <div
      v-show="changeslottypepvmid != pvm.id"
      :class="{ 'transparent-text': !pvm.slottype }"
      @click="changeStatusReport()"
    >
      {{ pvm.slottype ? pvm.slottype : "Select Dropdown" }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { Booking } from "@/models";

const store = useStore();
const timeSlotList: string[] = [
  "Morning",
  "Afternoon",
  "Full Day"
];
const pvbookings = computed(
  () => store.getters["pvbookings/getBookingsData"]
);
const props = defineProps<{
  pvm: Booking;
  changeslottypepvmid: string;
}>();
const emit = defineEmits(["slottypepvmselected"]);
const dynamicTimeSlotRefs = ref<{ [key: string]: HTMLElement | undefined }>({});
const setBookingsData = (data: Booking[]) =>
  store.commit("pvbookings/setBookings", data);
const sethasUnsavedChanges = (data: boolean) =>
store.commit("pvbookings/sethasUnsavedChanges", data);
const { pvm, changeslottypepvmid } = toRefs(props);
const changeStatusReport = () => {
  emit("slottypepvmselected", pvm.value.id);

  const dropdown = dynamicTimeSlotRefs.value[`dynamicTimeSlotRefs-${pvm.value.id}`] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const onSelectTimeSlot = async (id: string | undefined, val: string) => {
  const pmIndex = pvbookings.value.findIndex(
    (r: Booking) => r.id === id
  );
  pvbookings.value[pmIndex].slottype = val;
  // setBookingsData(pvbookings.value);
  sethasUnsavedChanges(true);
  pvm.value.id = id;
  emit("slottypepvmselected", "");
};
const closeDropdown = () => {
  emit("slottypepvmselected", "");
};
const status = ref("");
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent; 
  width: 100%;        
  height: 100%;       
  position: absolute; 
  top: 0;             
  left: 0;            
  cursor: pointer;   
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
