import { Module } from 'vuex';
import { RootState } from '../types';
import { EmailTemplatesState } from './types';
import { EmailTemplate } from '@/models/emailtemplate.model';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { Email } from '@/models';

/**
 * Default state
 */
export const state: EmailTemplatesState = {
  current: new EmailTemplate(),
  list: [],
  updates: [],
  clientemail: new Email(),
  feedbackemail: new Email(),
  landlordemail: new Email(),
  tenantemail: new Email(),
  invoiceemail: new Email(),
  dataentryemail: new Email(),
  deletebookingemail: new Email(),
  reporttypes: [],
  default: "All",
  target: [],
  chronorder: [],
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const emailtemplates: Module<EmailTemplatesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default emailtemplates;
