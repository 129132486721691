<template>
    <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="`emailmodal-label`"
      aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="email-modal-label">
              {{ title }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
  
          <div class="modal-body" style="  height: 85vh;      /* Set the height to 80% of the viewport height */
    overflow-y: auto;  /* Enable vertical scrollingif content overflows vertically */
    overflow-x: hidden;">
            <fieldset>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">To</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model.lazy="to" />
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Cc</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model.lazy="cc" />
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Bcc</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model.lazy="bcc" />
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-2">Attachments</label>
                <div class="col-sm-10">
                  <div class="row">
                    <div v-for="attachment in attachments" :key="`${attachment.path}-${attachment.generatingid}-${attachment.generatingstatus}`"
                      style="display: flex; align-items: center;" class="col-md-4">
                      <i :class="{
                        'text-success': selectedattachment(attachment),
                        'fas fa-2x fa-circle-notch fa-spin': attachment.generatingstatus == 'generating',
                        'far fa-2x fa-check-circle': attachment.generatingstatus == 'finished'
                      }" @click="toggleselection(attachment)"></i>
                      <span class="pl-1">{{ attachment.filename }}</span>
                    </div>
                  </div>
                  <Documents v-if="checkFinisedAttachments(attachments)" :buttontext="'Attach'" :entity="'booking'"
                    :key="`booking-${booking.emailattachmentdocuments.length}`" :documents="emailattachmentdocuments"
                    :path="'emailattachmentdocuments'" />
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Subject</label>
                <div class="col-sm-10 subject-editor">
                  <input type="text" class="form-control" v-model.lazy="subject" />
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Message</label>
                <div class="col-sm-10 body-editor" :height="messageheight">
                  <ckeditor :editor="bodyeditor" v-model="body" :config="bodyEditorConfig"></ckeditor>
                </div>
              </div>
            </fieldset>
          </div>
  
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal" @click.prevent="hide()">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" @click.prevent="send()"
              :disabled="!canSend()">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import $ from 'jquery';
  import Editor from "ckeditor5/ckeditor";
  import { defineProps, ref, computed, onMounted, inject, defineExpose, watch, nextTick } from 'vue';
  import _get from "lodash/get";
  import moment from "moment-timezone";
  import { v4 as uuidv4 } from 'uuid';
  import { useStore } from 'vuex';
  import { useToast } from "vue-toastification";
  import { Report, Bookedby, Dictionary, Email, EmailAttachment, EmailLogs, EmailTemplate, Landlord, Tenant, Fee, QCRule } from '@/models';
  import Documents from "@/components/document/Documents.vue";
  
  // Define props with their types
  const props = defineProps<{
    id: string;
    name: string;
    title: string;
    reporttype: string;
    target: string;
    chronorder: string;
    customer?: string;
    fromaddress: string;
    qcrules?: QCRule;
  }>();
  const actProperty: any = inject('actProperty');
  const toasted = useToast();
  
  // State and getters
  const store = useStore();
  const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
  const useremail = computed(() => store.getters['auth/email']);
  const clientemail = computed(() => store.getters['emailtemplates/clientemail']);
  const landlordemail = computed(() => store.getters['emailtemplates/landlordemail']);
  const tenantemail = computed(() => store.getters['emailtemplates/tenantemail']);
  const invoiceemail = computed(() => store.getters['emailtemplates/invoiceemail']);
  const booking = computed(() => store.getters['diary/booking']);
  const isQCuser = computed(() => store.getters['auth/isQCUser']);
  const invoices = computed(() => store.getters['diary/invoices']);
  const height = ref(window.innerHeight);
  const attachments = ref<EmailAttachment[]>([]);
  const findEmailTemplates = async (query?: {
    reporttype?: string;
    target?: string;
    chronorder?: string[];
    managedtype?: string;
    customer?: string;
  }): Promise<EmailTemplate> => {
    return await store.dispatch('emailtemplates/findEmailTemplates', query);
  };

  const to = computed({
    get: () => email.value.toaddresses ? email.value.toaddresses.join("; ") : "",
    set: (value: string) => {
      const addresses = value && value.trim().length > 0
        ? value.split(";").map(s => s.trim())
        : [];
      setEmailDeep({ path: 'toaddresses', data: addresses });
    }
});

const cc = computed({
  get: () => email.value.ccaddresses ? email.value.ccaddresses.join("; ") : "",
  set: (value: string) => {
    const addresses = value && value.trim().length > 0
      ? value.split(";").map(s => s.trim())
      : [];
    setEmailDeep({ path: 'ccaddresses', data: addresses });
  }
});

const bcc = computed({
  get: () => email.value.bccaddresses ? email.value.bccaddresses.join("; ") : "",
  set: (value: string) => {
    const addresses = value && value.trim().length > 0
      ? value.split(";").map(s => s.trim()).filter(s => s)
      : [];
    setEmailDeep({ path: 'bccaddresses', data: addresses });
  }
});
  
  const filterEmailTemplate = async (query?: {
    reporttype?: string;
    target?: string;
    chronorder?: string[];
    managedtype?: string;
    customer?: string;
  }): Promise<EmailTemplate> => {
    return await store.dispatch('emailtemplates/filterEmailTemplate', query);
  };
  
  const sendEmail = async (email: Email): Promise<string> => {
    return await store.dispatch('diary/sendEmail', email);
  };
  
  const setClientEmailDeep = async (payload: { path: string; data: any }): Promise<any> => {
    return await store.dispatch('emailtemplates/setClientEmailDeep', payload);
  };
  
  const setLandlordEmailDeep = async (payload: {
    path: string;
    data: any;
  }): Promise<any> => {
    return await store.dispatch('emailtemplates/setLandlordEmailDeep', payload);
  };
  
  const setTenantEmailDeep = async (payload: {
    path: string;
    data: any;
  }): Promise<any> => {
    return await store.dispatch('emailtemplates/setTenantEmailDeep', payload);
  };
  
  const setInvoiceEmailDeep = async (payload: {
    path: string;
    data: any;
  }): Promise<any> => {
    return await store.dispatch('emailtemplates/setInvoiceEmailDeep', payload);
  };
  
  const updateClientEmailConfirmationSent = async (bookingid: string): Promise<any> => {
    return await store.dispatch('diary/updateClientEmailConfirmationSent', bookingid);
  };
  
  const updateLandlordEmailConfirmationSent = async (bookingid: string): Promise<any> => {
    return await store.dispatch('diary/updateLandlordEmailConfirmationSent', bookingid);
  };
  
  const updateTenantEmailConfirmationSent = async (bookingid: string): Promise<any> => {
    return await store.dispatch('diary/updateTenantEmailConfirmationSent', bookingid);
  };
  
  const updateIssueInvoiceEmailSent = async (bookingid: string): Promise<any> => {
    return await store.dispatch('diary/updateIssueInvoiceEmailSent', bookingid);
  };
  
  const getEmailLogs = async (bookingid: string): Promise<EmailLogs> => {
    return await store.dispatch('diary/getEmailLogs', bookingid);
  };
  
  const setClientEmail = (email: Email): void => {
    store.commit('emailtemplates/setClientEmail', email);
  };
  
  const downloadReport = async (report: Report): Promise<any> => {
    return await store.dispatch('reports/generateReportPdf', report);
  }

  const downloadSummaryReport = async (report: Report): Promise<any> => {
    return await store.dispatch('reports/generateReportSummaryPdf', report);
  }
  
  
  const downloadNormalInvoice = async (report: Report): Promise<any> => {
    return await store.dispatch('reports/downloadInvoice', report);
  };
  
  const downloadProformaInvoice = async (report: Report): Promise<any> => {
    return await store.dispatch('reports/downloadProformaInvoice', report);
  };
  
  const removeRequest = (id: string): void => {
    store.commit('app/removeRequest', id);
  };
  
  const setLandlordEmail = (email: Email): void => {
    store.commit('emailtemplates/setLandlordEmail', email);
  };
  
  const setTenantEmail = (email: Email): void => {
    store.commit('emailtemplates/setTenantEmail', email);
  };
  
  const setInvoiceEmail = (email: Email): void => {
    store.commit('emailtemplates/setInvoiceEmail', email);
  };
  
  onMounted(() => {
    //loadAllEmailTemplates();
  
  });
  
  const init = async () => {
    setTemplate();

    // Set from address
    email.value.fromaddress = props.fromaddress;
    if (useremail.value) {
        email.value.fromaddress = useremail.value;
    }
      // values set as per qcrules
    email.value.toaddresses = [props.qcrules.email];
    email.value.ccaddresses = [];
    if (props.qcrules.ccbookedby && booking.value.bookedbyemail) {
      email.value.ccaddresses = [...email.value.ccaddresses,booking.value.bookedbyemail];
    }
    if (props.qcrules.issuetoll && booking.value.landlordemail) {
      email.value.ccaddresses = [...email.value.ccaddresses,booking.value.landlordemail];
    }
    if (props.qcrules.issuetott && booking.value.tenants.length > 0 && booking.value.tenants[0].ttemail.length > 0) {
      let tenantEmails = booking.value.tenants.map((t: Tenant) => t.ttemail);
      email.value.ccaddresses = [...email.value.ccaddresses,...tenantEmails];
    }
    

    // Prepare attachements
    booking.value.emailattachments = [];
    prepareEmailAttachmentList();
    setTimeout(() => {
      email.value.attachments = [...attachments.value];
    }, 1000);
  };
  
  const setTemplate = async () => {
    // Clear previous values
    setEmailDeep({ path: "bookingid", data: "", });
    setEmailDeep({ path: "target", data: [] });
    setEmailDeep({ path: "chronorder", data: [] });
    setEmailDeep({ path: "subject", data: "" });
    setEmailDeep({ path: "body", data: "" });
  
    // Check if this is a last minute booking
    let lastMinuteTemplate = await filterEmailTemplate({
      reporttype: props.reporttype,
      target: props.target,
      chronorder: [props.chronorder, 'Last Minute'],
      managedtype: booking.value.managedtype,
      customer: props.customer,
    })
  
    let chronorderfilter = [];
    if (props.chronorder)
      chronorderfilter.push(props.chronorder);
    if (lastMinuteTemplate?.id) {
      let lastminuteTemplate: EmailTemplate = lastMinuteTemplate;
      const createdatmoment = moment(booking.value.createdAt).utc();
      const bookingmoment = moment(booking.value.startdate).utc().startOf("day");
      let createdbeforedays = moment.duration(
        bookingmoment.diff(createdatmoment)
      ).asDays();
      if (createdbeforedays <= lastminuteTemplate.daysbefore) chronorderfilter.push('Last Minute');
    }
  
    let managedtype = booking.value.managedtype;
    await findEmailTemplates({
      reporttype: props.reporttype,
      target: props.target,
      chronorder: chronorderfilter,
      managedtype: managedtype,
      customer: props.customer,
    })
      .then(async (emailtemplate: EmailTemplate) => {
        if (!emailtemplate || !emailtemplate.id) {
          emailtemplate = new EmailTemplate();
        }
        let newemail: Email = new Email(emailtemplate);
        newemail.fromaddress = email.value.fromaddress;
        newemail.toaddresses = email.value.toaddresses;
        newemail.ccaddresses = email.value.ccaddresses;
        newemail.bccaddresses = email.value.bccaddresses;
  
        if (emailtemplate.cctoagent && booking.value.bookedbyemail) {
          newemail.ccaddresses.push(booking.value.bookedbyemail)
        }
        if (emailtemplate.cctolandlord) {
          booking.value.landlords.forEach((l: Landlord) => {
            newemail.ccaddresses.push(l.llemail);
          })
        }
  
        if (props.target === 'Managed Client' || props.target === 'Non-Managed Client')
          setClientEmail(newemail);
        else if (props.target === 'Landlord')
          setLandlordEmail(newemail);
        else if (props.target === 'Tenant')
          setTenantEmail(newemail);
        else if (props.target === 'Invoice')
          setInvoiceEmail(newemail);
  
        if (booking.value && booking.value.id) {
          setEmailDeep({
            path: "bookingid",
            data: booking.value.id,
          });
        }
        setEmailDeep({ path: "target", data: props.target });
        if (newemail.subject) newemail.subject = convertToPlain(normalise(newemail.subject));
        setEmailDeep({
          path: "subject",
          data: newemail.subject,
        });
        let emailbody = await addSignature(normalise(newemail.body));
        setEmailDeep({ path: "body", data: emailbody });
      })
      .catch((err: any) => actProperty.displayError(err));
  }
  
  const prepareEmailAttachmentList = () => {
    let invoicelist: EmailAttachment[] = [];
    if ((props.qcrules.includeinvoice || props.target === 'Invoice') && invoices.value?.length) {
      invoices.value.forEach((inv: Report) => {
        if (inv.id) {
          if (!inv.fees[0].name) {
            if (inv.attachments?.invoice) {
              invoicelist.push(prepareEmailAttachment(inv.attachments?.invoice));
            }
            else {
              const att = prepareEmailAttachment('');
              att.generatingid = uuidv4();
              att.generatingstatus = 'generating';
              downloadInvoice(inv, att);
              invoicelist.push(att);
            }
          }
          if (inv.fees[0].name) {
            if (inv.fees[0].name && inv.attachments?.proforma) {
              invoicelist.push(prepareEmailAttachment(inv.attachments?.proforma));
            }
            else {
              const att = prepareEmailAttachment('');
              att.generatingid = uuidv4();
              att.generatingstatus = 'generating';
              downloadInvoice(inv, att);
              invoicelist.push(att);
            }
          }
        }
      });
      attachments.value = [...invoicelist];
    }

    if(props.target != 'Invoice') {
      const reportatt = prepareEmailAttachment('');
      reportatt.generatingid = uuidv4();
      reportatt.generatingstatus = 'generating';
      attachments.value = [...invoicelist, reportatt];
      if(booking.value.report?.id) {
          downloadReport(booking.value.report)
          .then((key: string) => {
              const att = attachments.value.find((a) => a.generatingid === reportatt.generatingid);
              if(att) {
                att.generatingstatus = 'finished';
                att.filename = getAttachmentDisplayname(key);
                att.path = actProperty.prependDownloadsURL(key);
              }
            })
            .finally(() => removeRequest(reportatt.generatingid))
            .catch((err: any) => actProperty.displayError(err));
      }
      if(props.qcrules.includesummary) {
          const reportincsummaryatt = prepareEmailAttachment('');
          reportincsummaryatt.generatingid = uuidv4();
          reportincsummaryatt.generatingstatus = 'generating';
          attachments.value = [...attachments.value,reportincsummaryatt];
          downloadSummaryReport(booking.value.report)
          .then((key: string) => {
              const att = attachments.value.find((a) => a.generatingid === reportincsummaryatt.generatingid);
              if(att) {
                att.generatingstatus = 'finished';
                att.filename = getAttachmentDisplayname(key);
                att.path = actProperty.prependDownloadsURL(key);
              }
            })
            .finally(() => removeRequest(reportincsummaryatt.generatingid))
            .catch((err: any) => actProperty.displayError(err));
      }    
    }
  }
  
  const downloadInvoice = (report: Report, attachment: EmailAttachment): void => {
    if (report?.id && report.fees) {
      let fee: Fee = report.fees[0];
      if (!fee.name) {
        downloadProformaInvoice(report)
          .then((key: string) => {
            const att = attachments.value.find((a) => a.generatingid === attachment.generatingid);
            if(att) {
              att.generatingstatus = 'finished';
              att.filename = getAttachmentDisplayname(key);
              att.path = actProperty.prependDownloadsURL(key);
            }
          })
          .finally(() => removeRequest(attachment.generatingid))
          .catch((err: any) => actProperty.displayError(err));
      } else {
        downloadNormalInvoice(report)
          .then((key: string) => {
            attachment.generatingstatus = 'finished';
            attachment.filename = getAttachmentDisplayname(key);
            attachment.path = actProperty.prependDownloadsURL(key);
          })
          .finally(() => removeRequest(attachment.generatingid))
          .catch((err: any) => actProperty.displayError(err));
      }
    }
  }

  
  const prepareEmailAttachment = (val: string): EmailAttachment => {
    const filename = getAttachmentDisplayname(val);
    let attachment: EmailAttachment = new EmailAttachment({
      filename: filename,
      path: actProperty.prependDownloadsURL(val),
    });
    return attachment;
  }
  
  const toggleselection = (attachment: EmailAttachment) => {
    const index = email.value.attachments.findIndex(a => a.generatingid === attachment.generatingid);
    if (index < 0) email.value.attachments.push(attachment);
    else email.value.attachments.splice(index, 1);
  };
  
  const selectedattachment = (attachment: EmailAttachment) => {
    const index = email.value.attachments.findIndex(a => a.generatingid === attachment.generatingid);
    return index >= 0;
  };
  
  const getAttachmentDisplayname = (val: string): string => {
    const lastindex = val.lastIndexOf("/");
    return lastindex >= 0 ? val.substring(lastindex + 1) : "";
  };
    
  const body = computed({
    get: () => email.value.body,
    set: (data: string) => {
      setEmailDeep({ path: 'body', data });
    }
  });
  
  const subject = computed({
    get: () => email.value.subject,
    set: (data: string) => {
      setEmailDeep({ path: 'subject', data });
    }
  });
  
  
  const addSignature = async (body: string) => {
    let withsignature = `${body}`;
    await fetch('/assets/html/signature.html')
      .then(response => response.text())
      .then(data => {
        withsignature = `${body}<br/>${data}`
      })
      .catch(error => {
        // Handle any errors
        console.error('Error:', error);
      });
    return withsignature;
  }
  
  const setEmailDeep = (payload: { path: string; data: any }) => {
    if (props.target === "Managed Client" || props.target === "Non-Managed Client") {
      setClientEmailDeep(payload);
    } else if (props.target === "Landlord") {
      setLandlordEmailDeep(payload);
    } else if (props.target === "Tenant") {
      setTenantEmailDeep(payload);
    } else if (props.target === "Invoice") {
      setInvoiceEmailDeep(payload);
    }
  }
  
  const email = computed((): Email => {
    if (props.target === "Managed Client" || props.target === "Non-Managed Client") return clientemail.value;
    else if (props.target === "Landlord") return landlordemail.value;
    else if (props.target === "Tenant") return tenantemail.value;
    else if (props.target === "Invoice") return invoiceemail.value;
    else return new Email();
  });
  
  const normalise = (html: string): string => {
    placeholderMap.forEach((bookingpath: string, placeholder: string) => {
      let replacevalue = _get(booking.value, bookingpath, "**");
      if (placeholder === "Type of job external") {
        replacevalue = formatReportType(replacevalue);
      } else if (placeholder === "Booked with name") {
        replacevalue = preparebookedbyname(booking.value.bookedby);
      } else if (placeholder === "Client notes") {
        if (booking.value.clientnotes) replacevalue = booking.value.clientnotes;
        else replacevalue = undefined;
      } else if (placeholder === "Landlord name") {
        replacevalue = preparelandlordname(booking.value.landlords);
      } else if (placeholder === "Tenant name") {
        replacevalue = preparetenantname(booking.value.tenants);
      } else if (placeholder === "Invoice amount" && booking.value.invoices) {
        let total = booking.value.invoices.reduce(
          (sum: number, invoice: Report) => sum + invoice.totalFees(),
          0
        );
        if (isNaN(total)) total = 0;
        replacevalue = actProperty.formatCurrency(total);
      } else if (placeholder === "Date") {
        replacevalue = moment(replacevalue).utc().format("dddd Do MMMM YYYY");
      } else if (placeholder === "Appointment time") {
        let apptime = moment(booking.value.appointmenttime).utc().format("h:mm a");
        if (apptime === "12:00 am") apptime = "No Tenant but Fixed Time";
        if (apptime === "12:15 am") apptime = "Flexi all day";
        if (apptime === "12:20 am") apptime = "Flexi AM";
        if (apptime === "12:25 am") apptime = "Flexi PM";
        if (apptime === "12:30 am") apptime = "Flexi 9 till 1";
        if (apptime === "12:35 am") apptime = "Flexi 1 till 6";
        replacevalue = apptime;
      } else if (placeholder === "Confirm Yes Button") {
        replacevalue = generatebutton(
          "Yes",
          `${window.location.origin}/booking/confirmation/${booking.value.id}/yes?`
        );
      } else if (placeholder === "Confirm No Button") {
        replacevalue = generatebutton(
          "No",
          `${window.location.origin}/booking/confirmation/${booking.value.id}/no?`
        );
      } else if (placeholder === "Confirm Callback Button") {
        replacevalue = generatebutton(
          `Call Me`,
          `${window.location.origin}/booking/confirmation/${booking.value.id}/callback?`
        );
      } else if (placeholder === "Option 1 Button") {
        replacevalue = generatebutton(
          "Option 1",
          `${window.location.origin}/booking/confirmation/${booking.value.id}/option1?`
        );
      } else if (placeholder === "Option 2 Button") {
        replacevalue = generatebutton(
          "Option 2",
          `${window.location.origin}/booking/confirmation/${booking.value.id}/option2?`
        );
      } else if (placeholder === "Option 3 Button") {
        replacevalue = generatebutton(
          "Option 3",
          `${window.location.origin}/booking/confirmation/${booking.value.id}/option3?`
        );
      } else if (placeholder === "Option 4 Button") {
        replacevalue = generatebutton(
          "Option 4",
          `${window.location.origin}/booking/confirmation/${booking.value.id}/option4?`
        );
      } else if (placeholder === "Acknowledge Button") {
        replacevalue = generatebutton(
          "Acknowledge",
          `${window.location.origin}/dataentry/acknowledge/${booking.value?.id}?`
        );
      } else if (placeholder === "Download Dataentry Files Button") {
        replacevalue = generatebutton(
          "Download",
          `${window.location.origin}/dataentry/download/${booking.value?.id}?`
        );
      } else if (placeholder === "Access Branch") {
        if (booking.value.keypickup === 'From Agency') {
          replacevalue = booking.value.keypickupbranchname;
        }
        else {
          const keypickupaddress = booking.value.keypickupfromaddress.addressPreview;
          if (keypickupaddress.trim().length > 0) {
            replacevalue = keypickupaddress;
          }
        }
      }
      else if (placeholder === "Dataentry delivery date/time") {
        var bdatemoment = moment.utc(booking.value.startdateAsDate);
        var nowmoment = moment.utc();
        const diff = moment.duration(bdatemoment.diff(nowmoment)).asDays();
        if (diff > 2) {
          bdatemoment = bdatemoment.add(-2, "days");
        }
        replacevalue = bdatemoment.format('dddd Do MMMM YYYY hh:mma');
      }
      else if (placeholder === "Previous CI date") {
        let basereport: Report = booking.value.basereport;
        if(basereport?.id) {
          var reportdatemoment = moment.utc(basereport.date);
          replacevalue = reportdatemoment.format("DD/MM/YYYY");
        }
      }
      else if (placeholder === "Previous CI reference") {
        let basereport: Report = booking.value.basereport;
        if(basereport?.id) {
          replacevalue = `${basereport.ref}`;
        }
      }  else if (placeholder === "CO-Tenancy startdate note") {
        if(booking.value.jobtype === "checkout") {
          if(booking.value.internaljobtype === 'Check-Out - off ACT report') {
            let basereport = booking.value.basereport;
            if(basereport?.ref) {
              let displaydate = moment.utc(basereport.date).format("DD MMMM YYYY");
              replacevalue = `At Check-Out, we will compare against the report completed on ${displaydate}. If this doesn’t correspond with the tenancy start date please let us know as soon as possible.`;
            }
          }
          else {
            replacevalue = '';
          }
        }
    }
  
      html = html.replaceAll(
        `<span class="placeholder">[${placeholder}]</span>`,
        replacevalue
      );
    });
    html = html.replaceAll("<li>undefined</li>", "");
    return html;
  }
  
  const messageheight = computed(() => {
    return height.value - height.value * 0.80;
  });
  
  const convertToPlain = (html: string) => {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }
  const preparetenantname = (tenants: Tenant[]) => {
    let value: string[] = [];
    if (tenants && tenants.length) {
      value = tenants
        .filter((t: Tenant) => t.ttemail && t.ttname)
        .map((t: Tenant) => {
          return getFirstname(t.ttname);
        });
    }
    return commanames(value.join(", "));
  }
  
  const preparelandlordname = (landlords: Landlord[]) => {
    let value: string[] = [];
    if (landlords && landlords.length) {
      value = landlords
        .filter((l: Landlord) => l.llemail && l.llname)
        .map((l: Landlord) => {
          return getFirstname(l.llname);
        });
    }
    return commanames(value.join(", "));
  }
  
  const preparebookedbyname = (bookedby: Bookedby[]) => {
    let value: string[] = [];
    if (bookedby && bookedby.length) {
      value = bookedby
        .filter((b: Bookedby) => b.bbemail && b.bbname)
        .map((b: Bookedby) => {
          return getFirstname(b.bbname);
        });
    }
    return commanames(value.join(", "));
  }
  
  const getFirstname = (name: string) => {
    let firstname = '';
    if (name) {
      let splits = name.trim().split(" ");
      if (splits.length > 0) {
        firstname = splits[0];
        const lowercasename = firstname.toLowerCase();
        if (lowercasename === 'mr' || lowercasename === 'mrs' || lowercasename === 'miss' || lowercasename === 'ms') {
          if (splits.length > 1) {
            firstname = splits[1];
          }
        }
      }
    }
    return firstname;
  }
  
  const commanames = (val: string) => {
    let result = val;
    let index = val.lastIndexOf(",");
    if (index > 0) {
      result = val.substring(0, index) + ' and ' + val.substring(index + 2);
    }
    return result;
  }
  
  
  const generatebutton = (text: string, link: string) => {
    return `<span style="font-family: Helvetica, sans-serif;font-size: 100%;margin: 0;padding: 0;margin-top: 0">
        <!--[if mso]>
        <v:roundrect href="https://app.kontent.ai/gotoaction" style="width:273px;height:40px;v-text-anchor:middle;" arcsize="50%" stroke="f" fillcolor="#253746" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word">
            <w:anchorlock/>
            <v:textbox inset="0,0,0,0">
            <center>
        <![endif]-->
        <a href="${link}[targetemailaddress]" style="font-family: Helvetica, sans-serif;font-size: 14px;margin: 0;padding: 0 10px 0 10px;color: #e0db4c;margin-top: 0;font-weight: bold;line-height: 40px;letter-spacing: 0.1ch;text-transform: uppercase;background-color: #253746;border-radius: 5px;display: inline-block;text-align: center;text-decoration: none;white-space: nowrap;-webkit-text-size-adjust: none">&nbsp;&nbsp;${text}&nbsp;&nbsp;</a>
        <!--[if mso]>
            </center>
            </v:textbox>
        </v:roundrect>
        <![endif]-->
    </span>`;
  }
  
  const placeholderMap: Map<string, string> = new Map<string, string>([
    ["Type of job external", "jobtype"],
    ["Address", "address.displayaddress"],
    ["Booked with name", "bookedbyname"],
    ["Appointment time", "appointmenttime"],
    ["Client", "customer.companyName"],
    ["Client notes", "clientnotes"],
    ["Branch", "customer.branchName"],
    ["Access Branch", "keypickupbranchname"],
    ["Landlord name", "llname"],
    ["Tenant name", "ttnme"],
    ["Date", "startdate"],
    ["Start time", "starttime"],
    ["End time", "endtime"],
    ["Invoice amount", ""],
    ["Confirm Yes Button", ""],
    ["Confirm No Button", ""],
    ["Confirm Callback Button", ""],
    ["Option 1 Button", ""],
    ["Option 2 Button", ""],
    ["Option 3 Button", ""],
    ["Option 4 Button", ""],
    ["Acknowledge Button", ""],
    ["Download Dataentry Files Button", ""],
    ["Dataentry Report reference", "dataentryreport.ref"],
    ["Dataentry delivery date/time", ""],
    ["Previous CI date", ""],
    ["Previous CI reference", ""],
  ]);
  
  const formatReportType = (type: string) => {
    return actProperty.formatReportType(type, dictionary.value);
  }
  
  const bodyeditor = computed(() => {
    return Editor;
  });
  
  const bodyEditorConfig = computed(() => {
    return {
      toolbar: {
        items: ['heading', '|', 'bold', 'italic', 'link', '|', 'alignment:left', 'alignment:right', 'alignment:center',
          'alignment:justify', '|', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'outdent', 'indent',
          '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo', '|', 'placeholder'],
      },
      licenseKey:
        "UUI3NHlwcFFOOXZpdFlqSFlhSUFYbWpCRHY4MnpuQWR1Mk95K2ZZWkxKSlVGclUrSFAvWEpmYndNRzNKLU1qQXlOREF4TURZPQ==",
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true,
          },
        ],
      },
    };
  });
  
  const canSend = () => {
    return email.value.subject && email.value.body;
  }
  
  const send = async () => {
    if (canSend()) {
      await sendEmail(email.value)
        .then((response: string) => {
          if (response) {
            toasted.success(`Email successfully sent`);
          }
        })
        .then(() => {
          // Issue report confrmation dates are set in the backend
          // if (
          //   props.target === "Managed Client" ||
          //   props.target === "Non-Managed Client"
          // ) {
          //   updateClientEmailConfirmationSent(booking.value.id);
          // }
          
          if (props.target === "Landlord") {
            updateLandlordEmailConfirmationSent(booking.value.id);
          } else if (props.target === "Tenant") {
            updateTenantEmailConfirmationSent(booking.value.id);
          } else if (props.target === "Invoice") {
            updateIssueInvoiceEmailSent(booking.value.id);
          }
        }).then(() => {
          getEmailLogs(booking.value.id);
        });
    }
    hide();
  }
  
  
  const show = () => {
    if ($(`#${props.id}` + 'Backdrop').length == 0) {
      const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
      $('body').append(backdropDiv);
      $(`#${props.id}`).show();
    }
  }
  const hide = () => {
    if ($(`#${props.id}`).length > 0) {
      $(`#${props.id}` + 'Backdrop').remove();
      $(`#${props.id}`).hide();
    }
  };

  const checkFinisedAttachments   = (attachments: EmailAttachment[]) => {
    let ot = attachments.filter((f=> f.generatingstatus !== 'finished'));
    return ot.length == 0 ?  true :false;
  }
  
  
  const emailattachmentdocuments = computed(() => {
    return booking.value.emailattachmentdocuments;
  });
  
  watch(() => booking.value.emailattachmentdocuments, () => {
    if(booking.value.emailattachments.length > 0){
      email.value.attachments = [...new Map([...email.value.attachments, ...booking.value.emailattachments].map(obj => [obj.path, obj])).values()];
      attachments.value = [...new Map([...attachments.value, ...booking.value.emailattachments].map(obj => [obj.path, obj])).values()];
    }
  });
  
  defineExpose({ init, show, hide });
  </script>
  
  
  <style scoped lang="scss"></style>
  
  <style lang="scss">
  .placeholder {
    background-color: yellow;
  }
  
  .body-editor .ck-editor__editable_inline {
    min-height: 400px;
    max-height: 40vh;
    overflow-y: auto;
  }
  
  .subject-editor .ck-editor__editable_inline {
    max-height: 100px;
  }
  </style>