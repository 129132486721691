import _get from 'lodash/get';
import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';

import { Base } from '@/models';
import { toCamelCase, datetimeToUTC } from '@/utilities';

export class SMSLog extends Base {
  public templateid: string;
  public inout: string;
  public logdate: string;
  public message: string;
  public mobile: string;
  public bookingdate:string;
  public unread:boolean;

  public static parse(data: object) {
    const obj = new SMSLog;
    copyInto(obj, data);
    obj.templateid = _get(data, 'templateid', '');
    obj.inout = _get(data, 'inout', '');
    obj.logdate = _get(data, 'logdate', '');
    obj.message = _get(data, 'message', '');
    obj.mobile = _get(data, 'mobile', '');
    obj.bookingdate = _get(data, 'bookingdate', '');
    obj.unread = _get(data, 'unread', false);
    return obj;
  }

  /**
   * Constructor
   */
  public constructor(data?: Partial<SMSLog>) {
    super(data);
    data = toCamelCase(data);
    this.templateid = _get(data, 'templateid', '');
    this.inout = _get(data, 'inout', '');
    this.logdate = _get(data, 'logdate', '');
    this.message = _get(data, 'message', '');
    this.mobile = _get(data, 'mobile', '');
    this.bookingdate = _get(data, 'bookingdate', '');
    this.unread = _get(data, 'unread', false);
  }
}