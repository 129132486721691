<template>
  <div class="row">
    <div
      v-if="results && results.length"
      class="col-md-12 result-pane"
      ref="resultpane"
    >
      <!-- Filter results -->
      <div
        v-if="todaysunreadsmslogsresults && todaysunreadsmslogsresults.length"
      >
        <h5 class="col-md-12 pt-2 pb-2" style="color: red">Todays Jobs</h5>
        <div
          class="col-md-12 pt-2 pb-2"
          :class="{
            'selected-booking':
              (booking.id &&
                smsemailselectedbooking.id &&
                booking.id === smsemailselectedbooking.id) ||
              (booking.googleid &&
                smsemailselectedbooking.googleid &&
                booking.googleid === smsemailselectedbooking.googleid),
            'cancelled-booking': booking.cancelled,
            'focused-booking': isfocused(booking),
          }"
          v-for="booking in todaysunreadsmslogsresults"
          :key="booking.id"
        >
          <UnreadSmsEmailLogstResultTemplate
            :templateModel="{ appointmentData: booking }"
            :picolour="false"
            :rightclick="false"
            :showdate="true"
            @click.prevent="selectbooking(booking)"
            @removesmsbookingfromlocal="removesmsbookingfromlocal"
          />
        </div>
      </div>
      <div
        v-if="
          daterangeunreadsmslogsresults && daterangeunreadsmslogsresults.length
        "
      >
        <h5 class="col-md-12 pt-2 pb-2">Date Range</h5>
        <div
          class="col-md-12 pt-2 pb-2"
          :class="{
            'selected-booking':
              (booking.id &&
                smsemailselectedbooking.id &&
                booking.id === smsemailselectedbooking.id) ||
              (booking.googleid &&
                smsemailselectedbooking.googleid &&
                booking.googleid === smsemailselectedbooking.googleid),
            'cancelled-booking': booking.cancelled,
            'focused-booking': isfocused(booking),
          }"
          v-for="booking in daterangeunreadsmslogsresults"
          :key="booking.id"
        >
          <UnreadSmsEmailLogstResultTemplate
            :templateModel="{ appointmentData: booking }"
            :picolour="false"
            :rightclick="false"
            :showdate="true"
            @click.prevent="selectbooking(booking)"
            @removesmsbookingfromlocal="removesmsbookingfromlocal"
          />
        </div>
      </div>
      <div v-if="pastunreadsmslogsresults && pastunreadsmslogsresults.length">
        <h5 class="col-md-12 pt-2 pb-2">Past</h5>
        <div
          class="col-md-12 pt-2 pb-2"
          :class="{
            'selected-booking':
              (booking.id &&
                smsemailselectedbooking.id &&
                booking.id === smsemailselectedbooking.id) ||
              (booking.googleid &&
                smsemailselectedbooking.googleid &&
                booking.googleid === smsemailselectedbooking.googleid),
            'cancelled-booking': booking.cancelled,
            'focused-booking': isfocused(booking),
          }"
          v-for="booking in pastunreadsmslogsresults"
          :key="booking.id"
        >
          <UnreadSmsEmailLogstResultTemplate
            :templateModel="{ appointmentData: booking }"
            :picolour="false"
            :rightclick="false"
            :showdate="true"
            @click.prevent="selectbooking(booking)"
            @removesmsbookingfromlocal="removesmsbookingfromlocal"
          />
        </div>
      </div>
      <div
        v-if="futureunreadsmslogsresults && futureunreadsmslogsresults.length"
      >
        <h5 class="col-md-12 pt-2 pb-2">Future</h5>
        <div
          class="col-md-12 pt-2 pb-2"
          :class="{
            'selected-booking':
              (booking.id &&
                smsemailselectedbooking.id &&
                booking.id === smsemailselectedbooking.id) ||
              (booking.googleid &&
                smsemailselectedbooking.googleid &&
                booking.googleid === smsemailselectedbooking.googleid),
            'cancelled-booking': booking.cancelled,
            'focused-booking': isfocused(booking),
          }"
          v-for="booking in futureunreadsmslogsresults"
          :key="booking.id"
        >
          <UnreadSmsEmailLogstResultTemplate
            :templateModel="{ appointmentData: booking }"
            :picolour="false"
            :rightclick="false"
            :showdate="true"
            @click.prevent="selectbooking(booking)"
            @removesmsbookingfromlocal="removesmsbookingfromlocal"
          />
        </div>
      </div>
    </div>
    <div v-if="results.length === 0" class="col-md-12 result-pane">
      <p class="lead text-center align-middle">No Sms/Email logs found</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  inject,
  onMounted,
  defineProps,
  defineEmits,
  onBeforeUnmount,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useEvent } from "@/eventBus";
import { Booking } from "@/models";
import UnreadSmsEmailLogstResultTemplate from "./UnreadSmsEmailLogstResultTemplate.vue";
import Ably from "ably";
const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty");
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();
const emit = defineEmits(["smslogsselected", "removesmsbookingfromlocal"]);

// Props
const props = defineProps<{
  results: Booking[];
  todaysunreadsmslogsresults: Booking[];
  daterangeunreadsmslogsresults: Booking[];
  futureunreadsmslogsresults: Booking[];
  pastunreadsmslogsresults: Booking[];
}>();
// Store access
const store = useStore();
const router = useRouter();
const route = useRoute();

// Data
const smsemailselectedbookingid = ref("");
const unsavedchangesonform = ref(false);
// Computed properties and getters
const smsemailselectedbooking = ref(new Booking());

// Methods
const getBooking = (id: string): Promise<Booking> => {
  return store.dispatch("diary/getBooking", id);
};

const isfocused = (b: Booking) => {
  if (b?.id) {
    if (smsemailselectedbookingid.value) {
      return b.id === smsemailselectedbookingid.value;
    }
    // else if (props.futurebookingid) {
    //   return b.id === props.futurebookingid;
    // }
  }
  return false;
};

// Events handling
onMounted(() => {
  useEvent("appointmentRestoredInBookingTemplate", (booking: any) => {
    let todayindex = props.todaysunreadsmslogsresults.findIndex(
      (b: Booking) => b.id === booking.id
    );
    if (todayindex >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.todaysunreadsmslogsresults.splice(todayindex, 1);
    }
    let daterangeindex = props.daterangeunreadsmslogsresults.findIndex(
      (b: Booking) => b.id === booking.id
    );
    if (daterangeindex >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.daterangeunreadsmslogsresults.splice(daterangeindex, 1);
    }
    let pastindex = props.pastunreadsmslogsresults.findIndex(
      (b: Booking) => b.id === booking.id
    );
    if (pastindex >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.pastunreadsmslogsresults.splice(pastindex, 1);
    }
    let futureindex = props.futureunreadsmslogsresults.findIndex(
      (b: Booking) => b.id === booking.id
    );
    if (futureindex >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.futureunreadsmslogsresults.splice(futureindex, 1);
    }
  });
  useEvent("appointmentChangedInBookingTemplate", (booking: any) => {
    let todayindex = props.todaysunreadsmslogsresults.findIndex(
      (b: Booking) => b.id === booking.id
    );
    if (todayindex >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.todaysunreadsmslogsresults.splice(todayindex, 1);
      // eslint-disable-next-line vue/no-mutating-props
      props.todaysunreadsmslogsresults.push(booking);
    }
    let daterangeindex = props.daterangeunreadsmslogsresults.findIndex(
      (b: Booking) => b.id === booking.id
    );
    if (daterangeindex >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.daterangeunreadsmslogsresults.splice(daterangeindex, 1);
      // eslint-disable-next-line vue/no-mutating-props
      props.daterangeunreadsmslogsresults.push(booking);
    }
    let futureindex = props.futureunreadsmslogsresults.findIndex(
      (b: Booking) => b.id === booking.id
    );
    if (futureindex >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.futureunreadsmslogsresults.splice(futureindex, 1);
      // eslint-disable-next-line vue/no-mutating-props
      props.futureunreadsmslogsresults.push(booking);
    }
  });

  channel.subscribe("appointmentLoading", (message: any) => {
    if (message?.data) {
      if (message.data.deviceid != deviceid) return;
      if (!message.data.cancelled) {
        // If it is not a cancelled booking, highlight
        smsemailselectedbookingid.value = message.data.bookingid;
      } else {
        // If it is a cancelled booking, don't highlight, just set the smsemailselectedbookingid
        // so that the 2 second timeout does not open another tab
        smsemailselectedbookingid.value = message.data.bookingid;
      }
    }
  });
});

onBeforeUnmount(() => {
  channel.unsubscribe("appointmentLoading");
});

const selectbooking = async (booking: Booking) => {
  if (booking.id) {
    channel.publish("appointmentSelected", {
      deviceid: deviceid,
      bookingid: booking.id,
      inspectorid: booking.inspector?.id,
      googleid: booking.googleid,
      subtype: booking.subtype,
      jobtype: booking.jobtype,
      bookingdate: booking.bookingdate,
    });
    channel.publish("appointmentSelectedOnManagementFilters", {
      deviceid: deviceid,
      bookingid: booking.id,
    });
    unsavedchangesonform.value = false;
    try {
      const fetchedBooking = await getBooking(booking.id);
      if (fetchedBooking === undefined) {
        actProperty.displayError(
          `Could not find booking with ID ${route.params.id}`
        );
        router.push({ name: "newbooking" });
      }
    } catch (err) {
      actProperty.displayError(err);
    }
    smsemailselectedbooking.value = booking;
    emit("smslogsselected", booking, "fromunreadmessages");
    // Wait for 2 seconds, to check if there is form open on another tab and the appointment loaded
    setTimeout(() => {
      if (smsemailselectedbooking.value.id != smsemailselectedbookingid.value) {
        window.open(`/diary/${smsemailselectedbooking.value.id}`, "_blank");
        smsemailselectedbookingid.value = booking.id;
      }
    }, 2000);
    if (unsavedchangesonform.value) {
      smsemailselectedbookingid.value = "";
      unsavedchangesonform.value = false;
    }
  }
};

const removesmsbookingfromlocal = (booking: Booking, flag: string) => {
  emit("removesmsbookingfromlocal", booking, flag);
};
</script>

<style scoped lang="scss">
.result-pane {
  flex-grow: 1;
  height: 82vh;
  overflow-x: auto !important;
  overflow-y: auto !important;
  padding: 0 0 150px 0;
}

.booking-wrapper,
.booking-wrapper:hover {
  border-top: 1px solid #d9dadb;
  border-bottom: 1px solid #d9dadb;
}

.focused-booking.cancelled-booking,
.focused-booking.cancelled-booking:hover {
  color: #ffffff !important;
  // background: repeating-linear-gradient(135deg,
  //     #5c96c5,
  //     #5c96c5 5px,
  //     #052c4c 5px,
  //     #052c4c 10px);
  // background-color: transparent !important;
  background-image: conic-gradient(
      from var(--border-angle),
      #cfcfcf,
      #cfcfcf 50%,
      #cfcfcf
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #253746, #eadb40);
  font-weight: bolder;
}

.result-pane::-webkit-scrollbar {
  width: 20px;
}
</style>
