import _get from "lodash/get";
import _castArray from "lodash/castArray";
import { copyInto } from "@/utilities";

import { EmailAttachment, Base } from "@/models";
import { toCamelCase, datetimeToUTC } from "@/utilities";

export class EmailLog extends Base {
  public templateid: string;
  public inout: string;
  public logdate: string;
  public fromaddress: string;
  public toaddresses: string[];
  public ccaddresses: string[];
  public bccaddresses: string[];
  public subject: string;
  public message: string;
  public tenantresponse: string;
  public responsedescription: string;
  public responseemailaddress: string;
  public unread: boolean;
  public bookingdate:string;
  public attachments: EmailAttachment[];

  public bookingid: string;

  public static parse(data: object) {
    const obj = new EmailLog();
    copyInto(obj, data);
    obj.templateid = _get(data, "templateid", "");
    obj.inout = _get(data, "inout", "");
    obj.logdate = _get(data, "logdate", "");
    obj.subject = _get(data, "subject", "");
    obj.fromaddress = _get(data, "fromaddress", "");
    obj.toaddresses = _castArray(_get(data, "toaddresses", []));
    obj.ccaddresses = _castArray(_get(data, "ccaddresses", []));
    obj.bccaddresses = _castArray(_get(data, "bccaddresses", []));
    obj.message = _get(data, "message", "");
    obj.tenantresponse = _get(data, "tenantresponse", "");
    obj.responsedescription = _get(data, "responsedescription", "");

    obj.responseemailaddress = _get(data, "responseemailaddress", "");
    obj.unread = _get(data, 'unread', false);
    obj.bookingdate = _get(data, "bookingdate", "");
    obj.attachments = _get(data, 'attachments', []);
    return obj;
  }

  /**
   * Constructor
   */
  public constructor(data?: Partial<EmailLog>) {
    super(data);
    data = toCamelCase(data);
    this.templateid = _get(data, "templateid", "");
    this.inout = _get(data, "inout", "");
    this.logdate = _get(data, "logdate", "");
    this.subject = _get(data, "subject", "");
    this.fromaddress = _get(data, "fromaddress", "");
    this.toaddresses = _castArray(_get(data, "toaddresses", []));
    this.ccaddresses = _castArray(_get(data, "ccaddresses", []));
    this.bccaddresses = _castArray(_get(data, "bccaddresses", []));
    this.message = _get(data, "message", "");
    this.tenantresponse = _get(data, "tenantresponse", "");
    this.responsedescription = _get(data, "responsedescription", "");
    this.responseemailaddress = _get(data, "responseemailaddress", "");
    this.unread = _get(data, 'unread', false);
    this.bookingdate = _get(data, "bookingdate", "");
    this.attachments = _castArray(
      _get(data, "attachments", [])
    ).map((x: Partial<EmailAttachment>) => new EmailAttachment(x));
  }
}
