import { Module } from 'vuex';
import { RootState } from '../types';
import { SMSTemplatesState } from './types';
import { SMSTemplate } from '@/models/smstemplate.model';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { SMS } from '@/models';

/**
 * Default state
 */
export const state: SMSTemplatesState = {
  current: new SMSTemplate(),
  list: [],
  updates: [],
  tenantsms: new SMS(),
  reporttypes: [],
  default: "All",
  target: [],
  chronorder: [],
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const smstemplates: Module<SMSTemplatesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default smstemplates;
