<template>
  <div class="smstemplate">
    <fieldset>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Title</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" v-model="title" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Report type</label>
        <div class="col-sm-4">
          <multiselect-text
            v-model="reporttypes"
            :options="reportTypes"
            :allow-empty="true"
            :taggable="false"
            :custom-label="formatReportType"
            multiple
            @select="onSelectReporttype"
          >
          </multiselect-text>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Target</label>
        <div class="col-sm-4">
          <multiselect-text
            v-model="target"
            :options="targetList"
            :allow-empty="true"
            :taggable="false"
            multiple
            @select="onSelectTarget"
          >
          </multiselect-text>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Chron order</label>
        <div class="col-sm-4">
          <multiselect-text
            v-model="chronorder"
            :options="chronorderList"
            :allow-empty="true"
            :taggable="false"
            multiple
            @select="onSelectChronorder"
          >
          </multiselect-text>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label"
          >To be sent before (in days)</label
        >
        <div class="col-sm-1">
          <input type="number" class="form-control" v-model="daysbefore" />
        </div>

        <label class="col-sm-2 text-right col-form-label">Managed/Non-managed</label>
        <div class="col-sm-2">
          <singleselect-text
            v-model="managedtype"
            :options="managedtypeOptions"
            >
              <template 
                  #clear
                  v-if="managedtype">
                <i
                  @mousedown.prevent.stop="managedtype = ''"
                  class="multiselect__clear fa fa-times"
                  aria-label="Clear"
                ></i>
              </template>
            </singleselect-text>
        </div>

        <div class="col-sm-2">
          <div class="dx-field">
            <div class="dx-field-label">Default</div>
            <div class="dx-field-value">
              <DxSwitch :value="defaulttemplate" @value-changed="setSMSTemplateDeep({path: 'defaulttemplate', data: $event.value})"/>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Message</label>
        <div class="col-sm-9 body-editor">
          <ckeditor
            :editor="bodyeditor"
            v-model="body"
            :config="bodyEditorConfig"
          ></ckeditor>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { SMSTemplate, Dictionary } from "@/models";
import Editor from "ckeditor5/ckeditor";
import { DxSwitch} from 'devextreme-vue/switch';

const store = useStore();
const router = useRouter();
const route = useRoute();

const actProperty: any = inject('actProperty');

// State and Getters
const dictionary = computed((): Dictionary => store.getters['dictionary/current'] as Dictionary);
const smstemplate = computed(() => store.getters['smstemplates/current'] as SMSTemplate);

const setSMSTemplate = (smstemplate: SMSTemplate): void => {
  store.commit('smstemplate/setSMSTemplate', smstemplate);
};


const setSMSTemplateDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('smstemplates/setSMSTemplateDeep', payload);
};


const addReporttype = (reporttype: string): Promise<any> => {
  return store.dispatch('smstemplates/addReporttype', reporttype);
};

const setReporttypes = (reporttypes: string[]): Promise<any> => {
  return store.dispatch('smstemplates/setReporttypes', reporttypes);
};
// Mounted lifecycle hook
onMounted(async () => {
  if (isNewTemplate()) {
    setSMSTemplate(new SMSTemplate());
    return;
  }
});

const bodyeditor = computed(() => {
  return Editor;
});

const bodyEditorConfig = computed(() => {
  return {
    placeholderConfig: {
      types: [
        "Access Branch",
        "Address",
        "Appointment time",
        "Booked with name",
        "Branch",
        "Client",
        "Date",
        "End time",
        "Invoice amount",
        "Landlord name",
        "Start time",
        "Tenant name",
        "Type of job external"
      ],
    },
    toolbar: {
      items: ["placeholder"],
    },
  };
});

const title = computed({
  get: () => smstemplate.value.title,
  set: (data: string) => {
    setSMSTemplateDeep({
      path: 'title',
      data,
    });
  }
});

const body = computed({
  get: () => smstemplate.value.body,
  set: (data: string) => setSMSTemplateDeep({ path: 'body', data })
});

const reporttypes = computed({
  get: () => smstemplate.value.reporttypes,
  set: (types: string[]) => {
    setReporttypes(types);
  }
});
const onSelectReporttype = (type: string) => {
  addReporttype(type);
}

const managedtypeOptions: string[] = ["Managed", "Non-managed"];
const managedtype = computed({
  get: () => smstemplate.value.managedtype,
  set: (data: string) => {
    setSMSTemplateDeep({
      path: 'managedtype',
      data,
    });
  }
});

const defaulttemplate = computed({
  get: () => smstemplate.value.defaulttemplate,
  set: (data: boolean) => {
    setSMSTemplateDeep({
      path: 'defaulttemplate',
      data,
    });
  }
});

const editTemplate = (field: string, $event: any) => {
  setSMSTemplateDeep({
    path: field,
    data: $event.target.value,
  });
};

const reportTypes = computed((): string[] => {
  let list = ["inventory", "soc", "checkin", "checkout", "property visit"];
  if (dictionary.value && dictionary.value.reporttypes) {
    list = dictionary.value.reporttypes.map((t) => t.slug);
  }
  return list;
});

const targetList: string[] = ["Tenant"];
const chronorderList: string[] = [
  "Initial confirmation",
  "First Chaser",
  "Second Chaser",
  "Third Chaser",
  "Fourth Chaser",
  "Fifth Chaser",
  "Sixth Chaser",
  "Last Minute",
  "Reminder",
  "Confirmed",
  "Not Confirmed",
];

const formatReportType = (type: string) => {
  return actProperty.formatReportType(type, dictionary.value);
}

const target = computed({
  get: () => smstemplate.value.target,
  set: (data: string[]) => store.dispatch('smstemplates/setSMSTemplateDeep', { path: 'target', data })
});
const onSelectTarget = (type: string) => {
  store.dispatch('smstemplates/addTarget', type);
};

const chronorder = computed({
  get: () => smstemplate.value.chronorder,
  set: (data: string[]) => store.dispatch('smstemplates/setSMSTemplateDeep', { path: 'chronorder', data })
});
const onSelectChronorder = (type: string) => {
  store.dispatch('smstemplates/addChronorder', type);
};

const daysbefore = computed({
  get: () => smstemplate.value.daysbefore,
  set: (data: number) => store.dispatch('smstemplates/setSMSTemplateDeep', { path: 'daysbefore', data })
});

const isNewTemplate = () => {
  return (route.params.id as string) == "new";
}

</script>


<style lang="scss">
.placeholder {
  background-color: yellow;
}
.body-editor .ck-editor__editable_inline {
  min-height: 400px;
}
</style>