import { API } from "aws-amplify";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { PvBookingsState } from "./types";
import { Booking, Customer, Inspector, PmTenancy } from "@/models";
import _, { filter } from "lodash";
import moment from "moment-timezone";
/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<PvBookingsState, RootState> = {
  /**
   * Get all Tenancies
   *
   * @param {PvBookingsState} store
   * @param filters
   * @returns All Tenancies
   */
  async getTenancies(
    store: ActionContext<PvBookingsState, any>,
    data: any
  ): Promise<PmTenancy[]> {
    store.commit("app/addRequest", "getTenancies", { root: true });
    const options = {
      queryStringParameters: {
        startdate: data.startdate,
        enddate: data.enddate,
      },
    };
    return API.get("RestAPI", "/pmtenancies", options)
      .then((response) =>
        response.map((x: Partial<PmTenancy>) => new PmTenancy(x))
      )
      .then((tenancies: PmTenancy[]) => {
        store.commit("setTenancies", tenancies);
        return tenancies;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getTenancies", { root: true })
      );
  },
  /**
   * Get multiple Bookings
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getBookings(
    store: ActionContext<PvBookingsState, any>,
    filters: any
  ): Promise<Booking[]> {
    const options = {
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: _.pickBy(
        _.pick(filters, ["startdate", "enddate"])
      ),
    };

    // Check for page params for paginaton and offset
    // if (store.state.currentPage) {
    //   _.set(options, "queryStringParameters.page", store.state.currentPage);
    // } else {
    //   _.set(options, "queryStringParameters.page", 1);
    // }

    store.commit("app/addRequest", "getBookings", { root: true });

    return API.get("RestAPI", "/pvbookings", options)
      .then((response) => {
        // if (response.headers.hasOwnProperty("x-act-total-count")) {
        //   store.commit("setPvCount", response.headers["x-act-total-count"]);
        // } else {
        //   store.commit("setPvCount", null);
        // }
        // if (response.headers.hasOwnProperty("x-act-pv-page-limit")) {
        //   store.commit("setPageLimit", response.headers["x-act-pv-page-limit"]);
        // }
        return response.data
          .map((x: Partial<Booking>) => new Booking(x))
          .sort((b1: Booking, b2: Booking) =>
            moment.utc(b1.startdateAsDate).diff(moment.utc(b2.startdateAsDate))
          );
      })
      .then((bookings: Booking[]) => {
        const listclone = bookings.map((b: Booking) => new Booking(b));
        store.commit("setBookings", listclone);
        store.commit("sethasUnsavedChanges", false);
        return bookings;
      })
      .then((bookings: Booking[]) => {
        const listclone = bookings.map((b: Booking) => new Booking(b));
        store.commit("setOriginalBookings", listclone);
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getBookings", { root: true })
      );
  },
  /**
   * Set the page limit for pagination
   *
   * @param store
   * @param data
   */
  setPageLimit(store: ActionContext<PvBookingsState, any>, data): void {
    store.commit("setPageLimit", data);
  },

  /**
   * Set the page for the Reports query
   *
   * @param store
   * @param data
   */
  setCurrentPage(store: ActionContext<PvBookingsState, any>, data): void {
    store.commit("setCurrentPage", data);
  },

  /**
   * Set the page for the Reports query
   *
   * @param store
   * @param data
   */
  resetCurrentPage(store: ActionContext<PvBookingsState, any>): void {
    store.commit("setCurrentPage", 1);
  },
  /**
   * Get Customer list with given starting character
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @returns Company Name options
   */
  async getCustomers(store: ActionContext<PvBookingsState, any>) {
    const options = {
      queryStringParameters: {
        fieldlist: "_id,company_name,branch_name,address",
      },
    };

    store.commit("app/addRequest", "getCustomers", { root: true });
    return API.get("RestAPI", `/customers`, options)
      .then((response) =>
        response.map((x: Partial<Customer>) => new Customer(x))
      )
      .then((customers: Customer[]) => {
        store.commit("setCustomers", customers);
        return customers;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getCustomers", { root: true })
      );
  },
  /**
   * Add multiple Bookings
   *
   * @param {DiaryState} store
   * @param bookings: Booking[]
   * @returns Booking[]
   */
  async addPvBookings(
    store: ActionContext<PvBookingsState, any>,
    bookings: Booking[]
  ) {
    store.commit("app/addRequest", "addPvBookings", { root: true });
    let jsonBody: any = [];
    bookings.forEach((booking: Booking) => jsonBody.push(booking.toJSON()));
    return API.post("RestAPI", `/pvbookings`, { body: jsonBody })
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .finally(() =>
        store.commit("app/removeRequest", "addPvBookings", { root: true })
      );
  },
  /**
   * Add multiple Bookings
   *
   * @param {PvBookingsState} store
   * @param bookings: Booking[]
   * @returns Booking[]
   */
  async updatePvBookings(
    store: ActionContext<PvBookingsState, any>,
    bookings: Booking[]
  ) {
    store.commit("app/addRequest", "updatePvBookings", { root: true });
    let jsonBody: any = [];
    bookings.forEach((booking: Booking) => jsonBody.push(booking.toJSON()));
    return API.put("RestAPI", `/updatepvbookings`, { body: jsonBody })
      .then((response) =>
        response
          .map((x: Partial<Booking>) => new Booking(x))
          .sort((b1: Booking, b2: Booking) =>
            moment.utc(b1.startdateAsDate).diff(moment.utc(b2.startdateAsDate))
          )
      )
      .then((bookings: Booking[]) => {
        const listclone = bookings.map((b: Booking) => new Booking(b));
        store.commit("setBookings", listclone);
        store.commit("sethasUnsavedChanges", false);
        return bookings;
      })
      .then((bookings: Booking[]) => {
        const listclone = bookings.map((b: Booking) => new Booking(b));
        store.commit("setOriginalBookings", listclone);
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "updatePvBookings", { root: true })
      );
  },
  /**
   * Add single Bookings
   *
   * @param {PvBookingsState} store
   * @param bookings: Booking[]
   * @returns Booking[]
   */
  async updateSinglePvBookings(
    store: ActionContext<PvBookingsState, any>,
    bookings: Booking[]
  ) {
    store.commit("app/addRequest", "updatePvBookings", { root: true });
    let jsonBody: any = [];
    bookings.forEach((booking: Booking) => jsonBody.push(booking.toJSON()));
    return API.put("RestAPI", `/updatepvbookings`, { body: jsonBody })
      .then((response) =>
        response
          .map((x: Partial<Booking>) => new Booking(x))
          .sort((b1: Booking, b2: Booking) =>
            moment.utc(b1.startdateAsDate).diff(moment.utc(b2.startdateAsDate))
          )
      )
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "updatePvBookings", { root: true })
      );
  },
    /**
   * Add single Bookings
   *
   * @param {PvBookingsState} store
   * @param bookings: Booking[]
   * @returns Booking[]
   */
    async updateBookings(
      store: ActionContext<PvBookingsState, any>,
      bookings: Booking[]
    ) {
      store.commit("app/addRequest", "updateBookings", { root: true });
      let jsonBody: any = [];
      bookings.forEach((booking: Booking) => jsonBody.push(booking.toJSON()));
      return API.put("RestAPI", `/updatebookings`, { body: jsonBody })
        .then((response) =>
          response
            .map((x: Partial<Booking>) => new Booking(x))
            .sort((b1: Booking, b2: Booking) =>
              moment.utc(b1.startdateAsDate).diff(moment.utc(b2.startdateAsDate))
            )
        )
        .then((bookings: Booking[]) => {
          return bookings;
        })
        .finally(() =>
          store.commit("app/removeRequest", "updateBookings", { root: true })
        );
    },

  /**
   * Set booking changes and detect any change
   *
   * @param {PvBookingsState} store
   * @param bookings: Booking[]
   * @returns void
   */
  setBookings(
    store: ActionContext<PvBookingsState, any>,
    bookings: Booking[]
  ): Promise<void> {
    store.commit("app/addRequest", "setBookings", { root: true });
    // Detect changes
    for (let b of bookings) {
      const oldbooking: Booking = store.state.originallist.find(
        (oldb: Booking) => oldb.id === b.id
      );
      if (
        b.inspector?.id === oldbooking?.inspector.id &&
        b.scheduleddate === oldbooking.scheduleddate &&
        b.slottype === oldbooking.slottype &&
        !(b.startDate === b.endDate)
      ) {   
        b.changed = false;
      } else {
        b.changed = true;
      }
    }

    store.commit("setBookings", bookings);
    store.commit("app/removeRequest", "setBookings", { root: true });
    return;
  },

  updateCachedBookings(
    store: ActionContext<PvBookingsState, any>,
    bookings: Booking[]
  ): Promise<void> {
    store.commit("app/addRequest", "updateCachedBookings", { root: true });
    for (let b of store.state.list) {
      const oldbooking: Booking = store.state.originallist.find(
        (oldb: Booking) => oldb.id === b.id
      );
      if (oldbooking) {
        oldbooking.inspector = b.inspector;
        oldbooking.scheduleddate = b.scheduleddate;
        oldbooking.slottype = b.slottype;
        oldbooking.startDate = b.startDate;
        oldbooking.endDate = b.endDate;
      }
    }
    store.commit("app/removeRequest", "updateCachedBookings", { root: true });
    return;
  },

  /**
   * Get multiple Bookings for given PI for given day
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getBookingsForPI(
    store: ActionContext<PvBookingsState, any>,
    filters: any
  ): Promise<Booking[]> {
    const options = {
      queryStringParameters: _.pickBy(
        _.pick(filters, ["date", "inspectorid", "fieldlist", "period"])
      ),
    };

    // Check if bookings for this date is already present in inspector's bookings cache
    const inspector: Inspector = store.state.inspectorlist.find(
      (i: Inspector) => i.id === filters.inspectorid
    );
    let bookings: Booking[] = [];
    if (inspector?.bookingsmap.has(filters.date)) {
      return inspector.bookingsmap.get(filters.date);
    }

    // Call api to retrieve bookings from backend
    bookings = await API.get("RestAPI", "/bookings/pi", options)
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookinglist) => {
        store.commit("setInspectorBookings", {
          inspectorid: filters.inspectorid,
          date: filters.date,
          bookings: bookinglist,
        });
        return bookinglist;
      });
    return bookings;
  },
  /**
   * Get multiple Inspectors
   *
   * @param {InspectorsState} store
   * @param filters
   * @returns Multiple Inspectors
   */
  async getInspectors(
    store: ActionContext<PvBookingsState, any>,
    filters: any
  ): Promise<Inspector[]> {
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ["starts_with"])),
    };

    store.commit("app/addRequest", "getInspectors", { root: true });

    return API.get("RestAPI", "/inspectors", options)
      .then((response) =>
        response.map((x: Partial<Inspector>) => new Inspector(x))
      )
      .then((inspectors: Inspector[]) => {
        store.commit("setInspectors", inspectors);
        return inspectors;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getInspectors", { root: true })
      );
  },
  /**
   * Cancel a Booking
   *
   * @param {DiaryState} store
   * @param booking Booking
   * @returns void
   */
  async cancelBooking(
    store: ActionContext<PvBookingsState, any>,
    booking: Booking
  ): Promise<void> {
    store.commit("app/addRequest", "cancelBooking", { root: true });
    return await API.put(
      "RestAPI",
      `/bookings/cancel/${booking.booking.id}`,
      {}
    )
      .then(() => {
        booking.booking = new Booking();
        booking.inspector = new Inspector();
        booking.scheduleddate = "";
        booking.slottype = "";
        booking.status = "";
        const startTime = "T09:00:00.000Z";
        booking.startdate = subjobendDate(startTime, booking.startDate);
        booking.enddate = subjobendDate(startTime, booking.startDate);
        let bIndex = store.state.list.findIndex(
          (f: Booking) => f.id === booking.id
        );
        store.state.list[bIndex] = booking;
      })
      .then(() => store.commit("resetUnsavedChanges"))
      .finally(() =>
        store.commit("app/removeRequest", "cancelBooking", { root: true })
      );
  },
};
function subjobendDate(time, date){
  let value = "";
  if (time) {
    let dt = date;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
}
