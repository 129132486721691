import _ from 'lodash';
import Vue from 'vue';

import _snakeCase from 'lodash/snakeCase';

import { MutationTree } from 'vuex';
import { SMSTemplatesState } from './types';
import { SMSTemplate, SMS } from '@/models';

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setSMSTemplates', smstemplate)`
 */
export const mutations: MutationTree<SMSTemplatesState> = {

  /**
   * Set the report currently being viewed/edited
   *
   * @param state SMSTemplatesState
   * @param smstemplate SMSTemplate
   * @returns void
   */
  setCurrentSMSTemplate(state: SMSTemplatesState, smstemplate: SMSTemplate): void {
    state.current = smstemplate;
  },

  /**
   * Set the reports list
   *
   * @param state SMSTemplatesState
   * @param smstemplate SMSTemplate
   * @returns void
   */
  setSMSTemplates(state: SMSTemplatesState, smstemplates: SMSTemplate[]): void {
    state.list = smstemplates;
  },

  /**
   * Set the currently edited/viewed smstemplate
   * 
   * @param state SMSTemplatesState
   * @param smstemplate SMSTemplate
   * @returns void
   */
  setSMSTemplate(state: SMSTemplatesState, smstemplate: SMSTemplate): void {
    state.current = smstemplate;
  },

  /**
   * Set the currently edited/viewed tenant sms
   * 
   * @param state SMSTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setTenantSMSDeep(state: SMSTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.tenantsms, payload.path, payload.data);
  },

  /**
   * Set the currently edited/viewed tenant sms
   * 
   * @param state SMSTemplatesState
   * @param sms SMS
   * @returns void
   */
  setTenantSMS(state: SMSTemplatesState, sms: SMS): void {
    state.tenantsms = sms;
  },


  /**
   * Set the currently edited/viewed smstemplate
   * 
   * @param state SMSTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setSMSTemplateDeep(state: SMSTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.current, payload.path, payload.data);
  },


  /**
   * Adds a client
   */
  addClient(state: SMSTemplatesState, clientid: string): void {
    let clients: string[] = state.current.clients;
    if (!clients)
      clients = [];
    _.set(state.current, `clients`, clients.concat(clientid));
  },

  /**
   * Remove client from the given list
   * 
   * @param state 
   * @param flag 
   */
  removeClient(state: SMSTemplatesState, clientid: string): void {
    let clients: string[] = state.current.clients;
    if (clients) {
      let index = clients.findIndex((id) => id == clientid);
      if (index >= 0) {
        clients.splice(index, 1);
        _.set(state.current, `clients`, clients);
      }
    }
  },

  /**
   * Sets a reporttype list
   */
  setReporttypes(state: SMSTemplatesState, types: string[]): void {
    let reporttypes: string[] = state.current.reporttypes;
    if (!reporttypes)
      reporttypes = [];
    if (Array.isArray(types)) {
      _.set(state.current, `reporttypes`, types);
    }
  },

  /**
   * Adds a reporttype
   */
  addReporttype(state: SMSTemplatesState, reporttype: string): void {
    let reporttypes: string[] = state.current.reporttypes;
    if (!reporttypes)
      reporttypes = [];
    let index = reporttypes.indexOf(reporttype);
    if (index < 0)
      _.set(state.current, `reporttypes`, reporttypes.concat(reporttype));
  },

  /**
   * Remove reporttype from the given list
   * 
   * @param state 
   * @param flag 
   */
  removeReporttype(state: SMSTemplatesState, reporttype: string): void {
    let reporttypes: string[] = state.current.reporttypes;
    if (reporttypes) {
      let index = reporttypes.findIndex((type) => type == reporttype);
      if (index >= 0) {
        reporttypes.splice(index, 1);
        _.set(state.current, `reporttypes`, reporttypes);
      }
    }
  },


  /**
   * ++ Unsaved changes
   * 
   * @param state SMSTemplatesState
   * @param element string
   * @returns void
   */
  addUnsavedChange(state: SMSTemplatesState, element: string): void {
    state.updates = [...new Set(state.updates.concat([element]))];
  },


  /**
   * Reset Unsaved changes
   * 
   * @param state SMSTemplatesState
   * @returns void
   */
  resetUnsavedChanges(state: SMSTemplatesState): void {
    state.updates = [];
  },
   /**
     * Reporttypes
     * 
     * @param state EmailTemplatesState
     * @returns void
     */
     setReporttypesData(state: SMSTemplatesState,data:[]): void {
      state.reporttypes = data;
    },
    /**
     * Default
     * 
     * @param state EmailTemplatesState
     * @returns void
     */
    setDefault(state: SMSTemplatesState,data:string): void {
      state.default = data;
    },
    /**
     * Target
     * 
     * @param state EmailTemplatesState
     * @returns void
     */
    setTarget(state: SMSTemplatesState,data:[]): void {
      state.target = data;
    },
     /**
     * Default
     * 
     * @param state EmailTemplatesState
     * @returns void
     */
     setChronOrder(state: SMSTemplatesState,data:[]): void {
      state.chronorder = data;
    },

};
