import _, { transform } from "lodash";
import { API } from "aws-amplify";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { EmailTemplatesState } from "./types";

import { EmailTemplate, Email } from "@/models";

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<EmailTemplatesState, RootState> = {
  /**
   * Get multiple EmailTemplates
   *
   * @param {EmailTemplatesState} store
   * @param filters
   * @returns Multiple EmailTemplates
   */
  async getEmailTemplates(
    store: ActionContext<EmailTemplatesState, any>
  ): Promise<EmailTemplate[]> {
    const options = {};

    store.commit("app/addRequest", "getEmailTemplates", { root: true });

    return API.get("RestAPI", "/emailtemplates", options)
      .then((response) =>
        response.map((x: Partial<EmailTemplate>) => new EmailTemplate(x))
      )
      .then((emailtemplates: EmailTemplate[]) => {
        store.commit("setEmailTemplates", emailtemplates);
        return emailtemplates;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getEmailTemplates", { root: true })
      );
  },

  async findEmailTemplates(
    store: ActionContext<EmailTemplatesState, any>,
    query: {
      reporttype: string;
      target: string;
      chronorder: string[];
      customer: string;
      managedtype: string;
    }
  ): Promise<EmailTemplate> {
    let filteredlist: EmailTemplate[] = store.state.list.filter(
      (t: EmailTemplate) => {
        if (query.reporttype && t.reporttypes.indexOf(query.reporttype) < 0)
          return false;
        if (query.target && t.target.indexOf(query.target) < 0) return false;
        if (query.chronorder && query.chronorder.length > 0) {
          for (let i = 0; i < query.chronorder.length; i++) {
            let crord = query.chronorder[i];
            if (t.chronorder.indexOf(crord) < 0) return false;
          }
        }
        if (
          query.managedtype &&
          t.managedtype &&
          t.managedtype != query.managedtype
        )
          return false;
        if (
          !t.defaulttemplate &&
          query.customer &&
          t.clients.length &&
          t.clients.indexOf(query.customer) < 0
        )
          return false;
        return true;
      }
    );
    let emailtemplate: EmailTemplate = new EmailTemplate();
    if (filteredlist.length) {
      filteredlist.sort((e1: EmailTemplate, e2: EmailTemplate) => {
        if (e1.clients.length > 0) return 1;
        else if (e2.clients.length > 0) return -1;
        else return 0;
      });
      emailtemplate = filteredlist[0];

      // If more than one templates in the results, try to filter for given customer
      if (filteredlist.length > 1 && query.customer) {
        const tindex = filteredlist.findIndex(
          // Only check for non-default templates, where given customer is in selected list
          (t) => !t.defaulttemplate && t.clients.indexOf(query.customer) >= 0
        );
        if (tindex >= 0) {
          emailtemplate = filteredlist[tindex];
        }
      }
    }
    return emailtemplate;
  },

  async filterEmailTemplate(
    store: ActionContext<EmailTemplatesState, any>,
    query: {
      reporttype: string;
      target: string;
      chronorder: string[];
      customer: string;
      managedtype: string;
    }
  ): Promise<EmailTemplate> {
    let filteredlist: EmailTemplate[] = store.state.list.filter(
      (t: EmailTemplate) => {
        if (query.reporttype && t.reporttypes.indexOf(query.reporttype) < 0)
          return false;
        if (query.target && t.target.indexOf(query.target) < 0) return false;
        if (query.chronorder && query.chronorder.length > 0) {
          for (let i = 0; i < query.chronorder.length; i++) {
            let crord = query.chronorder[i];
            if (t.chronorder.indexOf(crord) < 0) return false;
          }
        }
        if (
          query.managedtype &&
          t.managedtype &&
          t.managedtype != query.managedtype
        )
          return false;
        if (
          !t.defaulttemplate &&
          query.customer &&
          t.clients.length &&
          t.clients.indexOf(query.customer) < 0
        )
          return false;
        return true;
      }
    );
    if (filteredlist.length) {
      filteredlist.sort((e1: EmailTemplate, e2: EmailTemplate) => {
        if (e1.clients.length > 0) return 1;
        else if (e2.clients.length > 0) return -1;
        else return 0;
      });
      return filteredlist[0];
    }
    return new EmailTemplate();
  },

  /**
   * Get one EmailTemplate
   *
   * @param {EmailTemplatesState} store
   * @param id
   * @returns Individual EmailTemplate
   */
  async getEmailTemplate(store: ActionContext<EmailTemplatesState, any>, id) {
    // New EmailTemplate
    if (id === "new") {
      const emailtemplate = new EmailTemplate();
      return emailtemplate;
    }
    // Search by Ref
    store.commit("app/addRequest", "getEmailTemplate", { root: true });
    return API.get("RestAPI", `/emailtemplates/${id}`, null)
      .then((response) => new EmailTemplate(response))
      .then((emailtemplate: EmailTemplate) => {
        store.commit("setEmailTemplate", emailtemplate);
        return emailtemplate;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getEmailTemplate", { root: true })
      );
  },

  /**
   * Add a EmailTemplate
   *
   * @param {EmailTemplatesState} store
   * @param emailtemplate EmailTemplate
   * @returns EmailTemplate
   */
  async addEmailTemplate(
    store: ActionContext<EmailTemplatesState, any>,
    emailtemplate: EmailTemplate
  ) {
    store.commit("app/addRequest", "addEmailTemplate", { root: true });
    let jsonBody = emailtemplate.toJSON();
    return API.post("RestAPI", `/emailtemplates`, { body: jsonBody })
      .then((response) => new EmailTemplate(response))
      .then((emailtemplate: EmailTemplate) => {
        store.commit(
          "setEmailTemplates",
          store.state.list.concat(emailtemplate)
        );
        store.commit("setEmailTemplate", emailtemplate);
        store.commit("resetUnsavedChanges");
        return emailtemplate;
      })
      .finally(() =>
        store.commit("app/removeRequest", "addEmailTemplate", { root: true })
      );
  },

  /**
   * Update a EmailTemplate
   *
   * @param {EmailTemplatesState} store
   * @param emailtemplate EmailTemplate
   * @returns Updated EmailTemplate
   */
  async updateEmailTemplate(
    store: ActionContext<EmailTemplatesState, any>,
    emailtemplate: EmailTemplate
  ) {
    store.commit("app/addRequest", "updateEmailTemplate", { root: true });
    return API.put("RestAPI", `/emailtemplates/${emailtemplate.id}`, {
      body: emailtemplate.toJSON(),
    })
      .then((response) => new EmailTemplate(response))
      .then((emailtemplate: EmailTemplate) => {
        store.commit("setEmailTemplate", emailtemplate);
        store.commit("resetUnsavedChanges");
        return emailtemplate;
      })
      .finally(() =>
        store.commit("app/removeRequest", "updateEmailTemplate", { root: true })
      );
  },

  /**
   * Delete a EmailTemplate
   *
   * @param {EmailTemplatesState} store
   * @param emailtemplate EmailTemplate
   * @returns Deleted EmailTemplate
   */
  async deleteEmailTemplate(
    store: ActionContext<EmailTemplatesState, any>,
    emailtemplate: EmailTemplate
  ): Promise<void> {
    store.commit("app/addRequest", "deleteEmailTemplate", { root: true });
    return API.del("RestAPI", `/emailtemplates/${emailtemplate.id}`, {})
      .then(() => {
         const newlist: EmailTemplate[] = _.remove(
                  store.state.list,
                  (c: EmailTemplate) => c.id !== emailtemplate.id
                );
         store.commit("setEmailTemplates", newlist);
      })
      .then(() => store.commit("setEmailTemplate", new EmailTemplate()))
      .then(() => store.commit("resetUnsavedChanges"))
      .finally(() =>
        store.commit("app/removeRequest", "deleteEmailTemplate", { root: true })
      );
  },

  /**
   * Edit a EmailTemplate
   *
   * @param {EmailTemplatesState} store
   * @param emailtemplate EmailTemplate
   */
  editEmailTemplate(
    store: ActionContext<EmailTemplatesState, any>,
    emailtemplate: EmailTemplate
  ) {
    store.commit("setEmailTemplate", emailtemplate);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets any deep emailtemplate property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setEmailTemplateDeep(
    store: ActionContext<EmailTemplatesState, any>,
    data
  ): void {
    store.commit("setEmailTemplateDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets any deep client email property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setClientEmailDeep(
    store: ActionContext<EmailTemplatesState, any>,
    data
  ): void {
    store.commit("setClientEmailDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets any deep feedback email property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setFeedbackEmailDeep(
    store: ActionContext<EmailTemplatesState, any>,
    data
  ): void {
    store.commit("setFeedbackEmailDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets deep landlord email property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setLandlordEmailDeep(
    store: ActionContext<EmailTemplatesState, any>,
    data
  ): void {
    store.commit("setLandlordEmailDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets deep tenant email property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setTenantEmailDeep(
    store: ActionContext<EmailTemplatesState, any>,
    data
  ): void {
    store.commit("setTenantEmailDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets deep email property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setInvoiceEmailDeep(
    store: ActionContext<EmailTemplatesState, any>,
    data
  ): void {
    store.commit("setInvoiceEmailDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets any deep dataentry email property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setDataentryEmailDeep(
    store: ActionContext<EmailTemplatesState, any>,
    data
  ): void {
    store.commit("setDataentryEmailDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets any deep deletebooking email property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setDeletebookingEmailDeep(
    store: ActionContext<EmailTemplatesState, any>,
    data
  ): void {
    store.commit("setDeletebookingEmailDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a client, and increments the "unsaved changes" count
   */
  addClient(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("addClient", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a client, and increments the "unsaved changes" count
   */
  removeClient(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("removeClient", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a reporttype, and increments the "unsaved changes" count
   */
  addReporttype(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("addReporttype", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a reporttype, and increments the "unsaved changes" count
   */
  removeReporttype(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("removeReporttype", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets a reporttypes, and increments the "unsaved changes" count
   */
  setReporttypes(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("setReporttypes", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a target, and increments the "unsaved changes" count
   */
  addTarget(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("addTarget", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a target, and increments the "unsaved changes" count
   */
  removeTarget(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("removeTarget", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets a targets, and increments the "unsaved changes" count
   */
  setTargets(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("setTargets", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a chronorder, and increments the "unsaved changes" count
   */
  addChronorder(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("addChronorder", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a chronorder, and increments the "unsaved changes" count
   */
  removeChronorder(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("removeChronorder", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets a chronorders, and increments the "unsaved changes" count
   */
  setChronorders(store: ActionContext<EmailTemplatesState, any>, data): void {
    store.commit("setChronorders", data);
    store.commit("addUnsavedChange");
  },
};
