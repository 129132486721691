<template>
  <div class="smstemplates">
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>SMS Templates</h1>
        </div>
        <div class="col-sm-6 text-right">
          <router-link class="btn btn-lg btn-primary" to="/smstemplate/new">
            <i class="fas fa-plus-square"></i>
            New
          </router-link>
        </div>
      </div>
    </header>
    <div>
      <div class="row">
        <div class="col-3">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Report Type</label>
            <div class="col-10">
              <multiselect-text
                v-model="reporttypeoptionsData"
                :options="jobtypelist"
                multiple
                :close-on-select="false"
                :allowEmpty="true"
                taggable
                @tag="addReportType"
              >
              </multiselect-text>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Default</label>
            <div class="col-sm-10">
              <singleselect-text
                v-model="defaultfn"
                :options="defaultlist"
                :allow-empty="false"
                :taggable="false"
              >
                <template #clear v-if="defaultfn">
                  <i
                    @mousedown.prevent.stop="clearDefault()"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Default"
                  ></i>
                </template>
              </singleselect-text>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Target</label>
            <div class="col-10">
              <multiselect-text
                v-model="targetoptionsData"
                :options="targetList"
                multiple
                :close-on-select="false"
                :allowEmpty="true"
                taggable
                @select="filterData"
              >
              </multiselect-text>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Chron Order</label>
            <div class="col-10">
              <multiselect-text
                v-model="chroneoptionsData"
                :options="chronorderList"
                multiple
                :close-on-select="false"
                :allowEmpty="true"
                taggable
                @select="filterData"
              >
              </multiselect-text>
            </div>
          </div>
        </div>
        <div class="col-1">
          <button class="btn btn-primary" @click.prevent="clearfilter()">
            <i class="fas fa-ban"></i> Clear
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!smstemplateslist.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No sms templates found</h2>
    </div>

    <table v-else class="table table-hover" style="padding-bottom: 60px">
      <thead>
        <th width="25%">Title</th>
        <th width="8%">Default</th>
        <th width="30%">Report Type</th>
        <th width="15%">Target</th>
        <th width="15%">Chron Order</th>
        <th width="7%"><span class="sr-only">Actions</span></th>
      </thead>
      <tbody>
        <tr
          v-for="smstemplate in smstemplateslist"
          :key="smstemplate.id"
          @click="edit(smstemplate)"
        >
          <td class="align-middle">{{ smstemplate.title }}</td>
          <td class="align-middle">
            <span class="multiselecttags">
              <span v-if="smstemplate.defaulttemplate" class="multiselecttag"
                >Default</span
              >
            </span>
          </td>
          <td class="align-middle">
            <span class="multiselecttags">
              <span
                class="multiselecttag"
                v-for="reporttype in smstemplate.reporttypes"
                :key="reporttype"
                >{{ reporttype }}</span
              >
            </span>
          </td>
          <td class="align-middle">
            <span class="multiselecttags">
              <span
                class="multiselecttag"
                v-for="t in smstemplate.target"
                :key="t"
                >{{ t }}</span
              >
            </span>
          </td>
          <td class="align-middle">
            <span class="multiselecttags">
              <span
                class="multiselecttag"
                v-for="c in smstemplate.chronorder"
                :key="c"
                >{{ c }}</span
              >
            </span>
          </td>
          <td class="align-middle text-right">
            <ul class="list-unstyled mb-0">
              <li class="list-item" @click.stop>
                <button
                  class="btn btn-outline-danger"
                  @click="remove(smstemplate)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { EmailTemplate, SMSTemplate } from "@/models";
import _, { transform } from "lodash";
const store = useStore();
const router = useRouter();
const toasted = useToast();

const hasBlockingRequests = computed(
  () => store.getters["app/hasBlockingRequests"] as boolean
);
const smstemplates = computed(
  (): SMSTemplate[] => store.getters["smstemplates/list"]
);
const chroneoptions = ref([]);
const reporttypeoptions = ref([]);
const smstemplateslist = ref([]);
const targetoptions = ref([]);
const defaultdata = ref("All");
const smstemplatesstate = ref(store.state.smstemplates);
const targetList: string[] = [
  "Booked By",
  "Data Entry",
  "Managed Client",
  "Non-Managed Client",
  "Tenant",
  "Landlord",
  "Invoice",
];
const defaultlist: string[] = ["All", "Default", "Non-default"];
const chronorderList: string[] = [
  "Initial confirmation",
  "First Chaser",
  "Second Chaser",
  "Third Chaser",
  "Fourth Chaser",
  "Fifth Chaser",
  "Sixth Chaser",
  "Last Minute",
  "Reminder",
  "Change Of Date",
  "Restore Booking",
  "Confirmed",
  "Not Confirmed",
  "Delete Booking",
  "Correspondance/Feedback to Client",
  "Issue Report",
];
const jobtypelist = computed(() => store.getters["diary/jobtypelist"]);
const targetoptionsData = computed({
  get: () => smstemplatesstate.value.target,
  set: (val: string[]) => addTargetData(val),
});
const reporttypeoptionsData = computed({
  get: () => smstemplatesstate.value.reporttypes,
  set: (val: string[]) => addReportType(val),
});
const chroneoptionsData = computed({
  get: () => smstemplatesstate.value.chronorder,
  set: (val: string[]) => addChrone(val),
});
const deleteSMSTemplate = async (smstemplate: SMSTemplate) => {
  await store.dispatch("smstemplates/deleteSMSTemplate", smstemplate);
};
const setReporttypesData = (data: string[]) => {
  return store.commit('smstemplates/setReporttypesData', data);
};
const setDefault = (data: string) => {
  return store.commit('smstemplates/setDefault', data);
};

const setTarget = (data: string[]) => {
  return store.commit('smstemplates/setTarget', data);
};
const setChronOrder = (data: string[]) => {
  return store.commit('smstemplates/setChronOrder', data);
};
const actProperty: any = inject("actProperty");

onMounted(async () => {
  await store.dispatch("smstemplates/getSMSTemplates");
  smstemplateslist.value = smstemplates.value;
  filterData();
});

const edit = (smstemplate: SMSTemplate) => {
  if ("id" in smstemplate) {
    router.push({
      name: "smstemplate",
      params: { id: smstemplate.id },
    });
  } else {
    actProperty.displayError("Error");
  }
};

const remove = async (smstemplate: SMSTemplate) => {
  actProperty
    .confirmPrompt()
    .then(() => deleteSMSTemplate(smstemplate))
    .then(() => {
      smstemplateslist.value = _.remove(
          smstemplates.value,
        (c: SMSTemplate) => c.id !== smstemplate.id
      );
    })
    .then(() => toasted.success("SMSTemplate deleted"))
    .catch((err: any) => actProperty.displayError(err));
};
const addReportType = async (data: string[]) => {
  reporttypeoptions.value = data;
  setReporttypesData(data);
  filterData();
};
const defaultfn = computed({
  get: () => smstemplatesstate.value.default,
  set: (val: string) => {
    defaultdata.value = val;
    setDefault(val);
    filterData();
  },
});
const addTargetData = async (data: string[]) => {
  targetoptions.value = data;
  setTarget(data);
  filterData();
};
const addChrone = async (data: string[]) => {
  chroneoptions.value = data;
  setChronOrder(data);
  filterData();
};
const clearDefault = async () => {
  defaultdata.value = "All";
  setDefault('All');
  filterData();
};
const clearfilter = () => {
  reporttypeoptions.value = [];
  targetoptions.value = [];
  chroneoptions.value = [];
  defaultdata.value = "All";
  smstemplateslist.value = smstemplates.value;
  setReporttypesData([]);
  setDefault('All');
  setChronOrder([]);
  setTarget([]);
};
const filterData = () => {
  smstemplateslist.value = smstemplates.value.filter((f: SMSTemplate) => {
      // Check if chroneoptions.value has any selected options
      const chronFilterActive = chroneoptionsData.value.length > 0;

      // Check if targetoptions.value has any selected options
      const targetFilterActive = targetoptionsData.value.length > 0;    
      // Check if reporttypeoptions.value has any selected options
      const reporttypeoptionsActive = reporttypeoptionsData.value.length > 0;

    // Check if defaultfn.value is not undefined or empty
    let setDefault = false;
    if (defaultfn.value === "All") {
      setDefault = undefined;
    } else if (defaultfn.value === "Default") {
      setDefault = true;
    } else {
      setDefault = false;
    }
    const defaultFilterActive = setDefault !== undefined;
    return (
      (!defaultFilterActive || f.defaulttemplate === setDefault) &&
      (!chronFilterActive ||
        f.chronorder.some((chron) => chroneoptions.value.includes(chron))) &&
      (!targetFilterActive ||
        f.target.some((target) => targetoptions.value.includes(target))) &&
      (!reporttypeoptionsActive ||
        f.reporttypes.some((report) =>
          reporttypeoptionsData.value.includes(report)
        ))
    );
  });
};
</script>

<style scoped lang="scss">
.multiselecttag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselecttags {
  display: block;
  font-size: 14px;
}
</style>
